import React, { useState } from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import useSessionStorage from "hooks/useSessionStorage";
import useLocalStorage from "hooks/useLocalStorageNew";
import useCookie from "hooks/useCookie";

export const cartContext = React.createContext();

toast.configure();

const Provider = props => {
  const { children } = props;

  const [cookie, updateCookie] = useCookie("ddstatoken", "");
  const [cartVals, setCartVals] = useSessionStorage("ddstaCart", []);

  const notify = (msg, type = "info", id = "toasty") => {
    if (!toast.isActive("toasty")) {
      toast(msg, { position: "top-center", type, toastId: id });
    }
  };

  const defaultCartState = {
    cart: cartVals,
    userToken: cookie,
    getCookieToken: () => cookie,
    updateToken: val => {
      updateCookie(val, 120);
    },
    removeToken: () => {
      updateCookie("", 0);
    },
    updateProducts: val => setCartVals([...cartVals, val]),
    updateQty: newcart => setCartVals(newcart),
    emptyCart: () => setCartVals([]),
    removeProduct: val => {
      const newCart = cartVals.filter(x => x.id !== val);
      setCartVals(newCart);
    },
    makeToast: (val, type, id) => notify(val, type),
  };

  return (
    <cartContext.Provider value={defaultCartState}>
      {children}
    </cartContext.Provider>
  );
};

const MyProvider = ({ element }) => <Provider>{element}</Provider>;
export default MyProvider;
